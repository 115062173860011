@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import "variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --lightThemeMainColor: #{$lightThemeMainColor};
  --darkThemeMainColor: #{$darkThemeMainColor};
  --swiper-theme-color: rgba(131,58,180,1) !important;
  --swiper-navigation-size: 24px !important;
}

body {
  font-family: $font-family;
  color: $generalTextColor;
  //background: transparent !important;
  background-color: var(--lightThemeMainColor); // Default to light
}
[data-theme='dark'] body {
  background-color: var(--darkThemeMainColor);
  color: var(--lightThemeMainColor);
}
.mainContainer {
  position: absolute;
  z-index: 2 !important;
  width: 100%;
}
.mainContainer.darkTheme {
  background: $darkThemeMainColor;
}
.mainContainer.lightTheme {
  background: $lightThemeMainColor;
}

button:focus {
  outline: none !important; /* Remove the default focus outline */
}

a.link {
  color: $linkColor;
}


@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.hidden-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* flag switcher */
.flagWrapper img {
  height: 24px;
}

/* Example CSS for the modal */
.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  position: relative;
  width: 300px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}


.w3m-modal {
  z-index: 100
}

.copyToClipboard {
  border: 1px solid #5046e5;
  background: #5046e5;
  padding: 10px 1rem;
  border-left: 0;
}

//swiper
.swiper-wrapper {
  padding-bottom: 1rem !important;
}
.swiper-pagination {
  width: fit-content !important;
}
.swiper-pagination-bullet {
  background-color: var(--swiper-theme-color)!important;
}
[data-theme='dark'] .swiper-pagination-bullet {
  background-color: $lightThemeMainColor !important;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute !important;
  bottom: 6px !important; /* Position buttons slightly above the bottom */
  top: auto !important; /* Reset top positioning */
  transform: translateY(0) !important; /* Reset any previous translation */
}

[data-theme='dark'] .swiper-button-next {
  color: $lightThemeMainColor !important;
}

[data-theme='dark'] .swiper-button-prev {
  color: $lightThemeMainColor !important;
}

.swiper-button-next {
  right: 0 !important; /* Position the right button close to the right edge */
}

.swiper-button-prev {
  right: 40px !important; /* Position the left button to the left of the right button */
  left: unset !important;
}

//nav

nav {
  top: 0 !important;
  max-height: 300px; /* Adjust this value as needed */
  overflow-y: auto; /* Allow scrolling if content exceeds max-height */
}